<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card :title="$route.params.id ? `Edit Area` : `Add New Area`">
        <form-create :id="$route.params.id" @on-success="$router.push({name: 'areas'})">
          <template v-slot:footer>
            <vs-button :to="{name:'areas'}" type="border" color="warning">Back</vs-button>
          </template>
        </form-create>
      </vx-card>
    </div>
  </div>
</template>

<script>
import FormCreate from './sections/Form.vue'
export default {
  components:{
    FormCreate
  }
}
</script>

<style>

</style>